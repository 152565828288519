import React, { Suspense, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { backButtonState } from '../src/atoms/BackButtonAtom'
import { pageState } from '../src/atoms/PageAtom'
import { pastPageState } from '../src/atoms/PastPageAtom'
import { walletState } from '../src/atoms/WalletAtom'
import { artistDataState } from '../src/atoms/artistDataAtom'

import ReactGA from 'react-ga4'
if (import.meta.env.VITE_STAGING === 'production')
  ReactGA.initialize(import.meta.env.VITE_GA_TAG)

const Home = React.lazy(() => import('./page/home'))
const MyMusic = React.lazy(() => import('./page/mymusic/index'))
const PlayLists = React.lazy(() => import('./page/playlist/index'))
const PlayList = React.lazy(() => import('./page/playlist/show'))
const PlayListNew = React.lazy(() => import('./page/playlist/new'))
const PlayListEdit = React.lazy(() => import('./page/playlist/edit'))
const Market = React.lazy(() => import('./page/market/index'))
const Artists = React.lazy(() => import('./page/artists/index'))
const Artist = React.lazy(() => import('./page/artists/show'))
const Collaborations = React.lazy(() => import('./page/collaborations/index'))
const Collaboration = React.lazy(() => import('./page/collaborations/show'))
const Gifts = React.lazy(() => import('./page/gift/index'))
const Gift = React.lazy(() => import('./page/gift/show'))
const NFTs = React.lazy(() => import('./page/nft/index'))
const NFTGenres = React.lazy(() => import('./page/nft/genres'))
const NFTShow = React.lazy(() => import('./page/nft/show'))
const Generatives = React.lazy(() => import('./page/generatives/index'))
const GenerativesShow = React.lazy(() => import('./page/generatives/show'))
const Tickets = React.lazy(() => import('./page/tickets/index'))
const CreatedTickets = React.lazy(() => import('./page/tickets/created'))
const TicketShow = React.lazy(() => import('./page/tickets/show'))
const TicketNew = React.lazy(() => import('./page/tickets/new'))
const TicketEdit = React.lazy(() => import('./page/tickets/edit'))
const TicketClaim = React.lazy(() => import('./page/tickets/claim'))
const ArticlesIndex = React.lazy(() => import('./page/articles/index'))
const ArticlesShow = React.lazy(() => import('./page/articles/show'))
const BenefitsIndex = React.lazy(() => import('./page/benefits/index'))

// Others
const OthersPage = React.lazy(() => import('./page/others/index'))
const OthersTermsArtist = React.lazy(() => import('./page/others/terms_artist'))
const OthersTermsFan = React.lazy(() => import('./page/others/terms_fan'))
const OthersPrivacy = React.lazy(() => import('./page/others/privacy'))
const OthersTokusho = React.lazy(() => import('./page/others/tokusho'))
const ContactPage = React.lazy(() => import('./page/contact'))

const ArtistMenuPage = React.lazy(() => import('./page/artistMenu/index'))

// Serials
const SerialsGetNft = React.lazy(() => import('./page/serials/getNft'))

// Mypage
const MypageIndex = React.lazy(() => import('./page/mypage/index'))

// Iam
const IamShow = React.lazy(() => import('./page/iam/show'))

// ArtistMenu > Contracts
const ArtistMenuContracts = React.lazy(
  () => import('./page/artistMenu/contracts')
)
const ArtistMenuContractsShow = React.lazy(
  () => import('./page/artistMenu/contracts/show')
)
const ArtistMenuContractNew = React.lazy(() => import('./page/contract/new'))
const ArtistMenuContractNftNew = React.lazy(
  () => import('./page/artistMenu/contracts/new')
)
const ArtistMenuContractsNftEdit = React.lazy(
  () => import('./page/artistMenu/contracts/edit')
)
const ArtistMenuContractsRegisterId = React.lazy(
  () => import('./page/artistMenu/contracts/registerId')
)

// ArtistMenu > Generatives
const ArtistMenuGeneratives = React.lazy(
  () => import('./page/artistMenu/generatives')
)
const ArtistMenuGenerativesNew = React.lazy(
  () => import('./page/artistMenu/generatives/new')
)
const ArtistMenuGenerativesEdit = React.lazy(
  () => import('./page/artistMenu/generatives/edit')
)

// ArtistMenu > Nfts
const ArtistMenuNfts = React.lazy(() => import('./page/artistMenu/nfts/nfts'))

// ArtistMenu > Nfts > Genres
const ArtistMenuNftGenres = React.lazy(
  () => import('./page/artistMenu/nfts/genres')
)

// ArtistMenu > Nfts > RelatedCreators
const ArtistRelatedCreators = React.lazy(
  () => import('./page/artistMenu/nfts/related_creators')
)

// ArtistMenu > Nfts > Gifts
const ArtistMenuGifts = React.lazy(
  () => import('./page/artistMenu/gifts/gifts')
)
const ArtistMenuGiftNewForm = React.lazy(
  () => import('./page/artistMenu/gifts/new')
)
const ArtistMenuGiftEditForm = React.lazy(
  () => import('./page/artistMenu/gifts/edit')
)
// Admin
const AdminMenuIndex = React.lazy(() => import('./page/admin'))
const AdminMenuEventLists = React.lazy(() => import('./page/admin/eventLists'))
const AdminSerials = React.lazy(() => import('./page/admin/serials'))
const AdminSerialsNftsShow = React.lazy(
  () => import('./page/admin/serials/nfts/show')
)
const AdminSerialsNftsNew = React.lazy(
  () => import('./page/admin/serials/nfts/new')
)

// ArtistMenu > Badges
const ArtistMenuBadges = React.lazy(
  () => import('./page/artistMenu/badges/index')
)

const ArtistMenuBadgesNew = React.lazy(
  () => import('./page/artistMenu/badges/new')
)

const ArtistMenuBadgesEdit = React.lazy(
  () => import('./page/artistMenu/badges/edit')
)

const Error404 = React.lazy(() => import('./page/error/404'))

type Props = {
  page?: string
  web3: Web3
  setWeb3: React.Dispatch<React.SetStateAction<Web3>>
}

function Routing(props: Props) {
  const [page, setPage] = useRecoilState(pageState)
  const [pastPage, setPastPage] = useRecoilState(pastPageState)
  const wallet = useRecoilValue(walletState)
  const [_, setBackButton] = useRecoilState(backButtonState)
  const artist = useRecoilValue(artistDataState)

  useEffect(() => {
    setPage(props.page ? props.page : '')

    // ブラウザのページの進退ボタン押下時にリロード
    window.addEventListener('popstate', () => {
      window.location.reload()
    })
  }, [])

  useEffect(() => {
    setPastPage('')
    if (import.meta.env.VITE_STAGING === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: `/${page}`,
      })
    }
  }, [page])

  const toCamelCase = (str: string) => {
    return str
      .split('_')
      .map((word, i) => {
        if (i === 0) {
          return word.toLowerCase()
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      })
      .join('')
  }

  const settingUri = () => {
    let uri = ''
    if (page !== 'home') {
      uri = page
    }

    return uri
  }

  useEffect(() => {
    history.pushState({}, '', `/${settingUri()}`)
    backButtonMethod()
  }, [page, artist, wallet])

  const backButtonMethod = () => {
    const pageSplit = page.split('/')
    if (pastPage && pastPage !== page) {
      setBackButton({
        displayText: '戻る',
        pageSlug: pastPage,
      })
    } else if (page?.toLowerCase().indexOf('artists/') !== -1) {
      setBackButton({ displayText: 'Artist一覧へ戻る', pageSlug: 'artists' })
    } else if (wallet && page?.toLowerCase().indexOf('playlist/') !== -1) {
      setBackButton({
        displayText: 'Playlist一覧へ戻る',
        pageSlug: 'playlist',
      })
    } else if (page?.toLowerCase().indexOf('others/') !== -1) {
      setBackButton({
        displayText: 'その他のページ一覧へ戻る',
        pageSlug: 'others',
      })
    } else if (page?.toLowerCase() === 'contact') {
      setBackButton({
        displayText: 'その他のページ一覧へ戻る',
        pageSlug: 'others',
      })
    } else if (
      page?.toLowerCase().indexOf('articles/') !== -1 &&
      page?.toLowerCase().indexOf('/detail') !== -1
    ) {
      setBackButton({
        displayText: '記事一覧へ戻る',
        pageSlug: 'articles',
      })
    } else if (
      page?.toLowerCase().indexOf('tickets/') !== -1 &&
      page?.toLowerCase().indexOf('/detail') !== -1
    ) {
      // Ticket > detail
      setBackButton({
        displayText: '所有しているTicket一覧',
        pageSlug: 'tickets',
      })
    } else if (
      page?.toLowerCase().indexOf('tickets/') !== -1 &&
      (page?.toLowerCase().indexOf('/new') !== -1 ||
        page?.toLowerCase().indexOf('/edit') !== -1)
    ) {
      // Ticket > edit
      setBackButton({
        displayText: '作成済みのTicket一覧',
        pageSlug: 'tickets/created',
      })
    } else if (
      // ArtistMenu > Contracts
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
      page?.toLowerCase().indexOf('/detail') !== -1
    ) {
      setBackButton({
        displayText: 'Contract一覧へ戻る',
        pageSlug: 'artist_menu/contracts',
      })
    } else if (
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
      page?.toLowerCase().indexOf('/new') !== -1
    ) {
      setBackButton({
        displayText: 'Contract Nftsへ戻る',
        pageSlug: `artist_menu/contracts/${pageSplit[2]}/detail`,
      })
    } else if (
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
      page?.toLowerCase().indexOf('/edit') !== -1
    ) {
      setBackButton({
        displayText: 'Contract Nftsへ戻る',
        pageSlug: `artist_menu/contracts/${pageSplit[2]}/detail`,
      })
    } else if (
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
      page?.toLowerCase().indexOf('/register_id') !== -1
    ) {
      setBackButton({
        displayText: 'Contract Nftsへ戻る',
        pageSlug: `artist_menu/contracts/${pageSplit[2]}/detail`,
      })
    } else if (
      // ArtistMenu > Generative
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/generatives') !== -1 &&
      page?.toLowerCase().indexOf('/detail') !== -1
    ) {
      setBackButton({
        displayText: 'Generative一覧へ戻る',
        pageSlug: 'artist_menu/generatives',
      })
    } else if (
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/generatives') !== -1 &&
      page?.toLowerCase().indexOf('/new') !== -1
    ) {
      setBackButton({
        displayText: 'Generative一覧へ戻る',
        pageSlug: `artist_menu/generatives`,
      })
    } else if (
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/generatives') !== -1 &&
      page?.toLowerCase().indexOf('/edit') !== -1
    ) {
      setBackButton({
        displayText: 'Generative一覧へ戻る',
        pageSlug: `artist_menu/generatives`,
      })
    } else if (
      wallet &&
      artist.public_id &&
      page?.toLowerCase().indexOf('artist_menu/nft/') !== -1 &&
      page?.toLowerCase().indexOf('/related_creators') !== -1
    ) {
      setBackButton({
        displayText: '登録済みのNFT一覧へ戻る',
        pageSlug: `artist_menu/nfts`,
      })
      // ------------------
      // 以下、管理画面系
      // ------------------
    } else if (
      wallet &&
      artist.public_id &&
      (page?.toLowerCase() == 'admin/serials' ||
        page?.toLowerCase() == 'admin/menu/event_lists')
    ) {
      setBackButton({
        displayText: '管理メニューへ戻る',
        pageSlug: `admin/menu`,
      })
    } else if (
      wallet &&
      artist.public_id &&
      (page?.toLowerCase() == 'admin/serials/nfts/new' ||
        (page?.toLowerCase().indexOf('admin/serials/nfts/') !== -1 &&
          page?.toLowerCase().indexOf('detail')))
    ) {
      setBackButton({
        displayText: 'シリアルコード管理中のNFT一覧へ戻る',
        pageSlug: `admin/serials`,
      })
    } else {
      setBackButton({ displayText: '', pageSlug: '' })
    }
  }

  let routing = <Error404 />
  routing =
    page?.toLowerCase() === 'home' ? <Home web3={props.web3} /> : routing

  // マイミュージック関係
  routing =
    page?.toLowerCase() === 'mymusic' ? <MyMusic web3={props.web3} /> : routing

  // プレイリスト関係
  routing = page?.toLowerCase() === 'playlist' ? <PlayLists /> : routing
  routing =
    page?.toLowerCase().indexOf('playlist/') !== -1 ? (
      <PlayList web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet &&
    page?.toLowerCase().indexOf('playlist/') !== -1 &&
    page?.toLowerCase().indexOf('/edit') !== -1 ? (
      <PlayListEdit web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet && page?.toLowerCase() === 'playlist/new' ? (
      <PlayListNew web3={props.web3} />
    ) : (
      routing
    )

  // マーケット関係
  routing =
    page?.toLowerCase() === 'market' ? <Market web3={props.web3} /> : routing

  // アーティスト関連
  routing = page?.toLowerCase() === 'artists' ? <Artists /> : routing
  routing =
    page?.toLowerCase().indexOf('artists/') !== -1 ? (
      <Artist web3={props.web3} />
    ) : (
      routing
    )

  // コラボ関連
  routing =
    page?.toLowerCase() === 'collaborations' ? <Collaborations /> : routing
  routing =
    page?.toLowerCase().indexOf('collaborations/') !== -1 ? (
      <Collaboration web3={props.web3} />
    ) : (
      routing
    )

  // ギフト関連
  routing = wallet && page?.toLowerCase() === 'gift' ? <Gifts /> : routing
  routing =
    wallet && page?.toLowerCase().indexOf('gift/') !== -1 ? <Gift /> : routing
  routing = page === 'nft' || page.indexOf('nft?') !== -1 ? <NFTs /> : routing
  routing = page === 'nft/genres' ? <NFTGenres /> : routing
  routing =
    page?.toLowerCase().indexOf('nft/') !== -1 &&
    page?.toLowerCase().indexOf('/detail') !== -1 ? (
      <NFTShow web3={props.web3} />
    ) : (
      routing
    )

  // ジェネラティブ関連
  routing = page?.toLowerCase() === 'generatives' ? <Generatives /> : routing
  routing =
    page?.toLowerCase().indexOf('generatives/') !== -1 &&
    page?.toLowerCase().indexOf('/detail') !== -1 ? (
      <GenerativesShow />
    ) : (
      routing
    )

  // チケット関連
  routing = wallet && page?.toLowerCase() === 'tickets' ? <Tickets /> : routing
  routing =
    wallet && page?.toLowerCase() === 'tickets/created' ? (
      <CreatedTickets />
    ) : (
      routing
    )
  routing =
    wallet &&
    page?.toLowerCase().indexOf('tickets/') !== -1 &&
    page?.toLowerCase().indexOf('/detail') !== -1 ? (
      <TicketShow />
    ) : (
      routing
    )
  routing =
    wallet && page?.toLowerCase() === 'tickets/new' ? <TicketNew /> : routing
  routing =
    wallet &&
    page?.toLowerCase().indexOf('tickets/') !== -1 &&
    page?.toLowerCase().indexOf('/edit') !== -1 ? (
      <TicketEdit />
    ) : (
      routing
    )
  routing =
    wallet &&
    page?.toLowerCase().indexOf('tickets/') !== -1 &&
    page?.toLowerCase().indexOf('/claim') !== -1 ? (
      <TicketClaim />
    ) : (
      routing
    )

  // 記事関連
  routing = page?.toLowerCase() === 'articles' ? <ArticlesIndex /> : routing
  routing =
    page?.toLowerCase().indexOf('articles/') !== -1 &&
    page?.toLowerCase().indexOf('/detail') !== -1 ? (
      <ArticlesShow />
    ) : (
      routing
    )

  // 特典関連
  routing = page?.toLowerCase() === 'benefits' ? <BenefitsIndex /> : routing

  // シリアルコード関連
  routing =
    page?.toLowerCase().indexOf('serials/get_nft') !== -1 ? (
      <SerialsGetNft setWeb3={props.setWeb3} />
    ) : (
      routing
    )

  // その他のページ
  routing = page?.toLowerCase() === 'others' ? <OthersPage /> : routing
  routing =
    page?.toLowerCase() === 'others/terms_artist' ? (
      <OthersTermsArtist />
    ) : (
      routing
    )
  routing =
    page?.toLowerCase() === 'others/terms_fan' ? <OthersTermsFan /> : routing
  routing =
    page?.toLowerCase() === 'others/privacy' ? <OthersPrivacy /> : routing
  routing =
    page?.toLowerCase() === 'others/tokusho' ? <OthersTokusho /> : routing

  // お問い合わせ
  routing = page?.toLowerCase() === 'contact' ? <ContactPage /> : routing

  // アーティストメニュー
  routing =
    wallet && artist.public_id && page?.toLowerCase() === 'artist_menu' ? (
      <ArtistMenuPage />
    ) : (
      routing
    )

  // マイページ
  routing =
    wallet && page === 'mypage' ? <MypageIndex web3={props.web3} /> : routing

  // iam
  routing = page === 'iam' ? <IamShow /> : routing

  // ArtistMenu > Contracts
  routing =
    wallet && artist.public_id && page === 'contract/new' ? (
      <ArtistMenuContractNew web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase() === 'artist_menu/contracts' ? (
      <ArtistMenuContracts />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
    page?.toLowerCase().indexOf('/detail') !== -1 ? (
      <ArtistMenuContractsShow web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
    page?.toLowerCase().indexOf('/new') !== -1 ? (
      <ArtistMenuContractNftNew web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
    page?.toLowerCase().indexOf('/edit') !== -1 ? (
      <ArtistMenuContractsNftEdit web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/contracts') !== -1 &&
    page?.toLowerCase().indexOf('/register_id') !== -1 ? (
      <ArtistMenuContractsRegisterId web3={props.web3} />
    ) : (
      routing
    )

  // ArtistMenu > Generatives
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase() === 'artist_menu/generatives' ? (
      <ArtistMenuGeneratives />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/generatives') !== -1 &&
    page?.toLowerCase().indexOf('/new') !== -1 ? (
      <ArtistMenuGenerativesNew />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/generatives') !== -1 &&
    page?.toLowerCase().indexOf('/edit') !== -1 ? (
      <ArtistMenuGenerativesEdit />
    ) : (
      routing
    )

  // ArtistMenu > Nfts
  routing =
    wallet && artist.public_id && page?.toLowerCase() === 'artist_menu/nfts' ? (
      <ArtistMenuNfts />
    ) : (
      routing
    )

  // ArtistMenu > Nfts > Genres
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/nft/') !== -1 &&
    page?.toLowerCase().indexOf('/genres') !== -1 ? (
      <ArtistMenuNftGenres />
    ) : (
      routing
    )
  // ArtistMenu > Nfts > RelatedCreators
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/nft/') !== -1 &&
    page?.toLowerCase().indexOf('/related_creators') !== -1 ? (
      <ArtistRelatedCreators />
    ) : (
      routing
    )
  // ArtistMenu > Nfts > Gifts
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/nft/') !== -1 &&
    page?.toLowerCase().indexOf('/gifts') !== -1 ? (
      <ArtistMenuGifts />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/nft/') !== -1 &&
    page?.toLowerCase().indexOf('/gifts/new') !== -1 ? (
      <ArtistMenuGiftNewForm />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/gifts/') !== -1 &&
    page?.toLowerCase().indexOf('/edit') !== -1 ? (
      <ArtistMenuGiftEditForm />
    ) : (
      routing
    )

  // Admin
  routing = wallet && page === 'admin/menu' ? <AdminMenuIndex /> : routing
  routing =
    wallet && page === 'admin/menu/event_lists' ? (
      <AdminMenuEventLists web3={props.web3} />
    ) : (
      routing
    )
  routing =
    wallet && page === 'admin/serials' ? (
      <AdminSerials web3={props.web3} />
    ) : (
      routing
    )

  routing =
    page?.toLowerCase().indexOf('admin/serials/nfts') !== -1 &&
    page?.toLowerCase().indexOf('/detail') !== -1 ? (
      <AdminSerialsNftsShow />
    ) : (
      routing
    )
  routing =
    wallet && page === 'admin/serials/nfts/new' ? (
      <AdminSerialsNftsNew />
    ) : (
      routing
    )

  // ArtistMenu > Badges

  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase() === 'artist_menu/badges' ? (
      <ArtistMenuBadges />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase() === 'artist_menu/badges/new' ? (
      <ArtistMenuBadgesNew />
    ) : (
      routing
    )
  routing =
    wallet &&
    artist.public_id &&
    page?.toLowerCase().indexOf('artist_menu/badges/') !== -1 &&
    page?.toLowerCase().indexOf('/edit') !== -1 ? (
      <ArtistMenuBadgesEdit />
    ) : (
      routing
    )

  return <Suspense fallback="loading...">{routing}</Suspense>
}

export default Routing
